<template>
  <div class="unlockUkey">
    <div class="unlockMatter" v-if="!unlocking">
      <div class="item-title">解锁注意事项</div>
      <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</p>
      <p>（2）将需要解锁的Ukey插入电脑USB接口进行解锁。（若遇到控件拦截，需要全部运行控件）</p>
      <p>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </p>
      <p><span class="el-icon-warning"></span> 解锁请插入一个Ukey操作，以免造成数据冲突，造成解锁失败。</p>
    </div>
    <template v-if="!unlocking">
      <div class="item-title">解锁信息</div>
      <div class="item-box" v-if="info.applyFormEntity&&info.applyFormEntity.orgInfoEntity">
        <div>证书序列号：<span>{{info.applyFormEntity.certSn}}</span></div>
        <div>所属机构/O：<span>{{info.applyFormEntity.orgInfoEntity.organization}}</span></div>
        <div>所属部门/OU：<span>{{info.applyFormEntity.orgInfoEntity.organizationalUnit}}</span></div>
        <template v-if="info.applyFormEntity.certType!== 2">
          <div>姓名/CN：<span>{{info.applyFormEntity.personInfoEntity.name}}</span></div>
          <div>证件类型：<span>{{IdentType(info.applyFormEntity.personInfoEntity.identType)}}</span></div>
          <div>证件号码：<span>{{ $util.IdNoSecret(info.applyFormEntity.personInfoEntity.identNo)}}</span></div>
        </template>
        <template v-else>
          <div>证书名称/CN：<span>{{info.applyFormEntity.orgInfoEntity.organization}}</span></div>
          <div>证件类型：<span>{{IdentType(info.applyFormEntity.orgInfoEntity.orgIdentType)}}</span></div>
          <div>证件号码：<span>{{$util.IdNoSecret(info.applyFormEntity.orgInfoEntity.orgIdentNo)}}</span></div>
        </template>
      </div>
      <div class="item-box" v-if="info.applyFormEntity&&info.applyFormEntity.certType===1">
        <div>证书序列号：<span>{{info.applyFormEntity.certSn}}</span></div>
        <div>姓名/CN：<span>{{info.applyFormEntity.personInfoEntity.name}}</span></div>
        <div>证件类型：<span>{{IdentType(info.applyFormEntity.personInfoEntity.identType)}}</span></div>
        <div>证件号码：<span>{{ $util.IdNoSecret(info.applyFormEntity.personInfoEntity.identNo)}}</span></div>
      </div>
      <div class="verify">
        <div class="hint">短信验证码将会发送至经办人手机号{{agent.mobile}}</div>
        <el-input @input="verCodeErr=false" @blur="verCodeErr=false" v-model="verCode" maxlength="6">
          <el-button type="text" @click="getUnlockVerCode" :disabled="verCodeTime>0" slot="suffix">{{verCodeTime>0?`重新获取(${verCodeTime})`:'获取验证码'}}</el-button>
        </el-input>
        <div v-if="verCodeErr" class="err">{{verCodeErr}}</div>
      </div>
      <el-button @click="unlockUkey" class="submitButn" type="primary">立即解锁</el-button>
    </template>
  </div>
</template>

<script>
// import { MessageBox } from 'element-ui'
import { IdentType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data() {
    return {
      IdentType,
      unlocking: false,
      transId: '',
      isFail: '',
      agent: {},
      verCode: '',
      verCodeTime: 0,
      verCodeErr: false,
      verCodeInfo: {
        mobile: '',
        sms_token: ''
      },
      intervals: []
    }
  },
  created() {
    this.transId = this.$route.query.transId
    // children label value
    this.$api.getAccountInfo().then(res => {
      let { name, identNo, mobile } = res.data
      this.agent = { name, identNo, mobile }
    })
  },
  mounted() {
    // const userAgent = navigator.userAgent.toLowerCase()
    // const isIE = /(msie\s|trident.*rv:)([\w.]+)/.test(userAgent)
    // if (!isIE) {
    //   MessageBox.alert('为了使用便捷、安全，请您更换IE浏览器', '温馨提示', {
    //     type: 'warning',
    //     confirmButtonText: '好的'
    //   })
    // }
  },
  methods: {
    unlockUkey() {
      let token = sessionStorage.getItem('token')
      if (this.verCode) {
        if (!this.verCodeInfo.sms_token) {
          this.$message.error('验证码错误/已过期')
          this.verCodeErr = '验证码错误/已过期'
        } else {
          let loading = this.$loading({
            lock: true,
            text: '证书解锁中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          setTimeout(() => {
            this.$parent.Ukey.connect()
              .then(certSn => {
                if (
                  certSn.toLowerCase() === this.info.applyFormEntity.certSn.toLowerCase()
                ) {
                  if ('ActiveXObject' in window) {
                    this.$parent.Ukey.unlockInit(
                      Unlock => {
                        let res = 0
                        try {
                          res = Unlock.unBlockPinHttps(
                            `${this.transId};${token};${this.verCodeInfo.mobile};${this.verCodeInfo.sms_token};${this.verCode}`
                          )
                        } catch (error) {
                          console.log(error)
                          loading.close()
                          this.$message.error('系统繁忙，请稍后再试')
                          return
                        }
                        if (res === 0) {
                          this.$parent.getTransactionDetail()
                        } else if (res === -140200) {
                          this.$message.error(
                            '网络通信失败，请检查网络，稍后再试'
                          )
                        } else if (res === -140201) {
                          if (Unlock.outMsg.length !== 0) {
                            this.$message.error(Unlock.outMsg)
                          } else {
                            this.$message.error('系统繁忙，请稍后再试')
                          }
                        } else if (res === -140203) {
                          this.$message.error(
                            '当前Ukey未锁定，无法进行解锁操作。'
                          )
                        } else if (res === 0xa0000005) {
                          this.$message.error('未检测到Ukey，请重新插入。')
                        } else {
                          this.$message.error('系统繁忙，请稍后再试')
                        }
                        loading.close()
                      },
                      () => {
                        loading.close()
                      }
                    )
                  } else {
                    this.$parent.Ukey.unlockInit(Unlock => {
                      return Unlock.handle('unBlockPinHttps', {
                        busiInfo: `${this.transId};${token};${this.verCodeInfo.mobile};${this.verCodeInfo.sms_token};${this.verCode}`
                      }).then(res => {
                        if (res.code === 0) {
                          this.$parent.getTransactionDetail()
                        } else if (res.code === -140200) {
                          this.$message.error(
                            '网络通信失败，请检查网络，稍后再试'
                          )
                        } else if (res.code === -140201) {
                          this.$message.error('系统繁忙，请稍后再试')
                        } else if (res.code === -140203) {
                          this.$message.error(
                            '当前Ukey未锁定，无法进行解锁操作。'
                          )
                        } else if (res.code === 0xa0000005) {
                          this.$message.error('未检测到Ukey，请重新插入。')
                        } else {
                          this.$message.error('系统繁忙，请稍后再试')
                        }
                        loading.close()
                      })
                    })
                  }
                } else {
                  this.$alert(
                    '当前插入Ukey非办理解锁业务所使用的，请更换Ukey',
                    '提示',
                    {
                      confirmButtonText: '我知道了'
                    }
                  )
                  loading.close()
                }
              })
              .catch(error => {
                console.log(error)
                loading.close()
              })
          }, 100)
        }
      } else {
        this.$message.error('请输入验证码')
        this.verCodeErr = '请输入验证码'
      }
    },

    convertCase(str) {
      var convertedStr = ''
      for (var i = 0; i < str.length; i++) {
        var char = str.charAt(i)
        if (char === char.toUpperCase()) {
          convertedStr += char.toLowerCase()
        }
      }

      return convertedStr
    },
    getUnlockVerCode() {
      this.$tencentCaptcha(val => {
        val['transId'] = this.transId
        this.$api.getUnlockVerCode(val).then(res => {
          this.verCodeTime = 60
          this.intervals.push(
            setInterval(() => {
              this.verCodeTime--
              if (this.verCodeTime == 0) {
                this.clearInterval()
              }
            }, 1000)
          )
          this.verCodeInfo = res.data
        })
      })
    },
    clearInterval() {
      for (let i of this.intervals) {
        clearInterval(i)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes loading {
  // 0% {
  //   transform: rotate(0deg);
  // }
  100% {
    transform: rotate(360deg);
  }
}
.unlockUkey {
  padding: 16px 24px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .verify {
    .hint {
      font-size: 14px;
      color: #303b50;
      line-height: 20px;
      margin-bottom: 12px;
    }
    ::v-deep.el-input__suffix {
      padding: 0 8px;
    }
    ::v-deep.el-input {
      width: 374px;
    }
    .err {
      margin-top: 8px;
      font-weight: 400;
      color: #ed5448;
      line-height: 21px;
    }
  }
  .submitButn {
    width: 182px;
    display: block;
    margin: 0 auto;
    margin-top: 140px;
  }
  .unlocking {
    padding-top: 140px;
    text-align: center;
    .icon {
      width: 52px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #e4e5e6;
      margin: 0 auto;
      border-radius: 50%;
      padding: 5px;
      img {
        width: 42px;
        height: 42px;
        animation: loading 0.6s linear infinite;
      }
    }
    .tt {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      margin: 24px auto;
    }
    .hint {
      width: 828px;
      background: #f7faff;
      border-radius: 2px;
      line-height: 94px;
      font-size: 16px;
      color: #ed5448;
      margin: 0 auto;
    }
  }
}
.unlockMatter {
  p {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 32px;
  }
  p:last-child {
    color: #ffb500;
  }
}
</style>