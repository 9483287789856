<template>
  <div class="certUnlock">
    <div class="returnCrumbs" v-if="stepActive<0">
      <router-link :to="{name:'serviceHall'}">
        服务大厅
      </router-link>
      <span>></span>
      <el-button type="text" :class="{'crumbsActive':stepActive===-1}">申请指南</el-button>
    </div>
    <Steps v-if="stepActive>=0" :steps="steps" :active="stepActive" />
    <Agreement v-model="agreementShow" @affirm="affirmAgreement" />
    <!-- <Helptext v-if="stepActive===-1" /> -->
    <Guide v-if="stepActive===-1"></Guide>
    <div class="step-content" v-else>
      <Information v-if="stepActive===0" :info="certInfo" />
      <UploadFile v-if="stepActive===1" :info="transactionDetail" />
      <CheckData v-if="[2,3].includes(stepActive)" :info="transactionDetail" />
      <UnlockUkey v-if="[7].includes(stepActive)" :info="transactionDetail" />
      <Complete v-if="[11,12,13].includes(stepActive)" :info="transactionDetail" />
    </div>
  </div>
</template> 

<script>
import { Ukey } from './ukey'
// 公共
import Steps from '../components/steps.vue'
import CheckData from '../components/checkData.vue'
import UploadFile from '../components/uploadFile.vue'
import Complete from '../components/complete.vue'
import Agreement from '../components/agreement.vue'
// 私有
// import Helptext from './helptext.vue'
import Guide from './guide.vue'
import Information from './information.vue'
import UnlockUkey from './unlockUkey.vue'

export default {
  components: {
    Steps,
    // Helptext,
    Information,
    UploadFile,
    CheckData,
    UnlockUkey,
    Complete,
    Agreement,
    Guide
  },
  data () {
    return {
      transId: '',
      steps: [
        {
          name: '信息填写',
          icon: 'service-step-zltx',
          step: [0]
        },
        {
          name: '上传资料',
          icon: 'service-step-yjzl',
          step: [1]
        },
        {
          name: '资料审核',
          icon: 'service-step-zlsh',
          step: [2, 3]
        },
        {
          name: '在线解锁',
          icon: 'service-step-zsgx',
          step: [7]
        },
        {
          name: '业务完成',
          icon: 'service-step-ywc',
          step: [11, 12, 13]
        }
      ],
      certInfo: { certSn: '' },
      stepActive: -1,
      transactionDetail: {},
      Ukey: null,
      agreementShow: false
    }
  },
  created () {
    this.Ukey = new Ukey(this)
    let transId = this.$route.query.transId
    if (transId) {
      this.transId = transId
    } else {
      this.stepActive = -1
    }
  },
  methods: {
    ukeyLogined () { },
    getTransactionDetail () {
      this.$api
        .getTransactionDetail({ transactionId: this.transId })
        .then(res => {
          this.certInfo = res.data.applyFormEntity
          this.transactionDetail = res.data
          this.stepActive = res.data.transactionEntity.status
        })
    },
    affirmAgreement () {
      this.stepActive = 0
    }
  },
  watch: {
    transId (val) {
      if (val) {
        this.getTransactionDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>