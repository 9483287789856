<template>
  <div class="information-form">
    <el-form ref="information" size="small" label-position="top" :model="formData" label-width="80px">
      <div class="item-title">证书信息</div>
      <el-table class="info-table" :data="[info]" border style="width: 100%">
        <el-table-column prop="certSn" label="证书序列号" min-width="220">
        </el-table-column>
        <el-table-column prop="orgInfoEntity.organization" label="所属机构/O" v-if="info.certType!==1">
        </el-table-column>
        <el-table-column prop="orgInfoEntity.organizationalUnit" label="部门/OU" v-if="info.certType!==1">
        </el-table-column>
        <template v-if="info.certType!== 2">

          <el-table-column prop="personInfoEntity.name" label="证书名称/CN">
          </el-table-column>
          <el-table-column label="证件类型">
            <template slot-scope="scope">
              {{ IdentType(scope.row.personInfoEntity.identType) }}
            </template>
          </el-table-column>
          <el-table-column prop="personInfoEntity.identNo" label="证件号码">
            <template slot-scope="scope">
              {{ $util.IdNoSecret(scope.row.personInfoEntity.identNo) }}
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column prop="orgInfoEntity.organization" label="证书名称/CN">
          </el-table-column>
          <el-table-column label="证件类型">
            <template slot-scope="scope">
              {{ IdentType(scope.row.orgInfoEntity.orgIdentType) }}
            </template>
          </el-table-column>
          <el-table-column prop="orgInfoEntity.orgIdentNo" label="证件号码">
          </el-table-column>
        </template>
      </el-table>
      <div class="item-title" v-if="info.sealInfoEntitys">印章图样</div>
      <div class="patternImg" v-if="info.sealInfoEntitys"><img :src="info.sealInfoEntitys[0].patternFilePath"></div>
      <div>
        <div class="item-title">经办人基本信息<span class="hint">（经办人手机号用于接收证书密码，业务验证码，证书解锁信息等）</span></div>
        <div class="item-box">
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人姓名">
            <el-input disabled v-model="agent.name"></el-input>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人身份证号">
            <el-input disabled v-model="agent.identNo"></el-input>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人手机号">
            <el-input disabled v-model="agent.mobile"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-button class="submitButn" @click="operationSubmit" :loading="loading" type="primary">确定解锁</el-button>
  </div>

</template>

<script>
import { IdentType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data() {
    return {
      loading: false,
      confirmationShow: false,
      IdentType,
      agent: {},
      formData: {
        businessType: 7,
        certSn: ''
      }
    }
  },
  created() {
    // children label value
    this.$api.getAccountInfo().then(res => {
      let { name, identNo, mobile } = res.data
      this.agent = { name, identNo, mobile }
    })
  },
  methods: {
    operationSubmit() {
      this.loading = true
      this.formData.certSn = this.info.certSn
      let transId = this.$route.query.transId
      if (transId) {
        this.formData['transId'] = transId
      }
      this.$api.operationSubmit(this.formData).then(res => {
        this.loading = false
        if (res.code === '0') {
          this.$parent.transId = res.data.id
          this.$router.push({
            query: { transId: res.data.id }
          })
        } else if (res.code === '306') {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '继续办理',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
          })
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {}
          })
        }
      })
    },
    indate(time) {
      let startTime = new Date().getTime()
      let endTime = new Date(time.replace(/-/g, '/')).getTime()
      let day = Math.floor((endTime - startTime) / (1000 * 3600 * 24))
      let years = Math.floor(Math.abs(day) / 365)
      return `${years ? years + '年' : ''}${day % 365}天`
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.information-form {
  text-align: center;
  padding: 16px 24px;
  ::v-deep.el-form {
    text-align: left;
    .el-radio {
      margin: 10px 20px 10px 0px;
    }
    .item-box {
      display: flex;
      flex-wrap: wrap;
      .el-form-item:nth-child(3n) {
        margin-right: 0;
      }
      .el-form-item {
        width: 368px;
        margin-right: 73px;
      }
    }
    .el-form-item {
      margin-bottom: 32px;
      width: 809px;
    }
    .el-form-item__label {
      line-height: 19px;
      margin-bottom: 14px;
      font-size: 14px;
      padding: 0;
      color: #303b50;
    }
    .label-position-left {
      width: auto;
      .el-form-item__label {
        float: left;
        width: 94px;
        line-height: 32px;
        margin-bottom: 0;
      }
      .el-form-item__content {
        margin-left: 94px;
      }
    }
  }
  ::v-deep.info-table {
    margin-bottom: 32px;
    width: 100%;
    .el-table__header {
      width: 100% !important;
    }
    .el-table__body {
      width: 100% !important;
    }
    thead {
      color: #303b50;

      .cell {
        font-weight: normal;
      }
    }
    .el-table__row {
      td {
        height: 80px;
        color: #5c6269;
      }
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #fff;
    }
  }
  .submitButn {
    width: 182px;
    margin: 24px auto;
  }
}
.patternImg {
  margin-bottom: 32px;
  img {
    width: 128px;
  }
}
</style>