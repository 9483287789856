<template>
  <div class="guide">
    <div class="title">解锁指南</div>
    <div class="listMain">
      <div class="list-title">办理流程</div>
      <ul>
        <li>插入Ukey</li>
        <li>信息确认 </li>
        <li>上传资料</li>
        <li>资料审核 </li>
        <li>密码重置</li>
        <li>业务完成 </li>
      </ul>
    </div>
    <div class="listMain otherMain">
      <div class="list-title">注意事项 </div>
      <div>
        <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</p>
        <p>（2）将需要解锁的Ukey插入电脑USB接口，使用“<span>IE浏览器</span>”进行解锁（若遇到控件拦截，需要全部运行控件）</p>
        <p>（3）填写解锁信息并提交，审核通过后即解锁成功，可以前往【豸信CA数字证书助手】进行修改密码。</p>
        <div class="list-title other"><span class=" el-icon-warning"></span> 解锁请插入一个Ukey操作，以免造成数据冲突，造成解锁失败。 </div>
      </div>
    </div>
    <div class="listMain">
      <div>
        <div class="list-title">所需证明资料 </div>
        <div>
          <div class="means">1、【机构证书】</div>
          <p>（1）证书信息确认成功后，下载《数字证书解锁申请表》并填写完整，加盖公章</p>
          <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
          <p>（3）法人身份证原件复印件，加盖公章</p>
          <p>（4）本次业务办理人身份证原件复印件，加盖公章</p>
        </div>
      </div>
      <div>
        <div class="means other">2、【机构个人证书】</div>
        <p>（1）信息备案成功后，下载《数字证书解锁申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）证书持有人身份证原件复印件，加盖公章</p>
        <p>（5）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>
      <div>
        <div class="means other">3、【个人证书】</div>
        <p>【本人办理解锁】</p>
        <p>（1）信息备案成功后，下载《数字证书解锁申请表》并填写完整，加盖公章</p>
        <p>（2）证书持有人身份证原件复印件，加盖公章</p>
        <p>【经办人办理解锁】</p>
        <p>（1）信息备案成功后，下载《数字证书解锁申请表》并填写完整</p>
        <p>（2）信息备案成功后，下载《证书解锁申请授权委托书》并填写完整</p>
        <p>（3）证书持有人身份证原件复印件</p>
        <p>（4）本次业务办理人身份证原件复印件</p>
      </div>
    </div>
    <div class="btn">
      <el-button @click="certUnlock" :loading="loading" type="primary">解锁申请</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'guide',
  data() {
    return {
      loading: false
    }
  },
  created() {},
  methods: {
    certUnlock() {
      this.loading = true
      this.$parent.Ukey.connect()
        .then(certSn => {
          this.$parent.Ukey.unlockInit(
            () => {
              this.$api.getCertBySn({ certSn, transType: 7 }).then(res => {
                this.loading = false
                if (res.code === '0') {
                  this.$parent.certInfo = { ...res.data, certSn }
                  this.$parent.agreementShow = true
                } else if (res.code === '306') {
                  this.$alert(res.msg, '提示', {
                    confirmButtonText: '继续办理',
                    type: 'warning'
                  }).then(() => {
                    this.$router.push({
                      name: 'serviceCenter',
                      query: { type: 1 }
                    })
                  })
                } else {
                  this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: () => {}
                  })
                }
              })
            },
            () => {
              this.loading = false
            }
          )
        })
        .catch(err => {
          this.loading = false
          if (err && err.message) {
            this.$message.error(err.message)
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.guide {
  padding: 0 24px;
  background: #fff;
  .title {
    font-size: 16px;
    color: #303b50;
    line-height: 52px;
    border-bottom: 1px #dee2e8 solid;
    margin-bottom: 32px;
  }
  .listMain {
    padding-left: 44px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
      width: 600px;
    }
    &.otherMain {
      display: block;
      > div {
        width: 100%;
      }
      p {
        padding-top: 32px;
        &:first-of-type {
          padding: 0;
        }
      }
      span {
        font-weight: 600;
      }
    }
    .list-title {
      color: #303b50;
      font-size: 14px;
      font-weight: 700;
      position: relative;
      height: 19px;
      line-height: 19px;
      margin-bottom: 24px;
      &::before {
        content: '';
        position: absolute;
        left: -28px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: linear-gradient(180deg, #f4f4fd 0%, #819ae7 100%);
      }
      &.other {
        left: -23px;
        color: #edb01b;
        padding-left: 30px;
        padding-top: 28px;
        &::before {
          width: 0;
          height: 0;
        }
        span {
          font-size: 16px;
          color: #ffb500;
          margin-right: 10px;
        }
      }
    }
    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
    }
    li {
      width: 97px;
      height: 42px;
      line-height: 37px;
      font-size: 12px;
      color: #303b50;
      position: relative;
      z-index: 1;
      border: 1px solid #dae1f4;
      border-left-width: 4px;
      border-bottom-width: 4px;
      margin-right: 48px;
      border-radius: 2px;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        right: -48px;
        top: 20px;
        display: inline-block;
        width: 48px;
        height: 1px;
        background: #dae1f4;
      }
      &:last-of-type::after {
        width: 0px;
      }
    }
    .means {
      color: #e02020;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 2px;
      &.other {
        padding-top: 32px;
      }
    }
    p {
      color: #303b50;
      font-size: 12px;
      line-height: 16px;
      padding-top: 16px;
    }
  }
  .btn {
    text-align: center;
    padding: 20px 0 48px;
    .el-button {
      padding: 12px 65px;
    }
  }
}
</style>