import { UkeyMiddleware, PARAM } from '@/utils/ukeyMiddleware'
import { MessageBox } from 'element-ui'
// import request from '@/utils/request'
function getErr (err) {
  return err ? { message: err } : {}
}
function isIe () {
  return ('ActiveXObject' in window)
}
export class Ukey {
  // eslint-disable-next-line space-before-function-paren
  constructor (self) {
    this.Unlock = {}
    this.self = self
    this.certInfo = {}
    this.applicationName = 'wellhope'
    this.containerName = 'LAB_USERCERT'
    this.ukeyMiddleware = new UkeyMiddleware('admin')
    this.ukeyMiddlewareUnlock = new UkeyMiddleware('onlineUnlock')
  }
  connect () {
    if (isIe()) {
      return this.ukeyMiddleware.connect().then(res => {
        if (res.code !== 0) {
          const h = this.self.$createElement
          MessageBox.alert(
            h('p', undefined, [
              h('span', undefined, '您还未安装豸信CA数字证书助手，请'),
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.self.$util.downloadById(this.self.CONFIG.APPID)
                    }
                  }
                },
                '【下载安装】'
              ),
              h('span', undefined, '后进行解锁')
            ]),
            '提示',
            {
              type: 'warning',
              confirmButtonText: '好的'
            }
          )
          throw getErr(false)
        } else {
          return this.ukeyMiddleware.handle('GetUkeyList', {})
        }
      }).then(res => {
        if (res.code === 0 && res.data.length > 0) {
          // return Promise.resolve(res.data)
          this.ukeyName = res.data[0]['uKey-alias']
          return this.disconnectUkey()
        } else {
          MessageBox.alert(
            '请插入需要解锁的Ukey。',
            '提示',
            {
              type: 'warning',
              confirmButtonText: '好的'
            }
          )
          throw getErr(false)
        }
      }).then(() => {
        return this.ukeyMiddleware.handle('ConnectUkey', { 'uKey-alias': this.ukeyName, applicationName: this.applicationName, containerName: this.containerName })
      }).then(res => {
        if (res.code !== 0) { throw getErr('Ukey链接失败') }
        this.devHandle = res.data.devHandle
        return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 })
      }).then(res => {
        if (res.code !== 0) { throw getErr('读取证书信息失败') }
        let base64Cert = res.data.certContent
        this.base64Cert = base64Cert
        return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
      }).then(res => {
        if (res.code !== 0) { throw getErr('读取证书信息失败') }
        console.log(res.data.certInfo,'certInfo');
        this.certSn = res.data.certInfo.toLowerCase()
        return Promise.resolve(this.certSn)
      })
    } else {
      return this.ukeyMiddlewareUnlock.connect().then(res => {
        if (res.code !== 0) {
          const h = this.self.$createElement
          MessageBox.alert(
            h('p', undefined, [
              h('span', undefined, '您还未安装豸信CA数字证书助手，请'),
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.self.$util.downloadById(this.self.CONFIG.APPID)
                    }
                  }
                },
                '【下载安装】'
              ),
              h('span', undefined, '后进行解锁')
            ]),
            '提示',
            {
              type: 'warning',
              confirmButtonText: '好的'
            }
          )
          throw getErr(false)
        } else {
          return this.ukeyMiddlewareUnlock.handle('GetUkeyList', {})
        }
      }).then(res => {
        if (res.code === 0 && res.data.certSN) {

          // return Promise.resolve(res.data)
          this.certSn = res.data.certSN.toLowerCase()
          return Promise.resolve(this.certSn)
        } else {
          MessageBox.alert(
            '请插入需要解锁的Ukey。',
            '提示',
            {
              type: 'warning',
              confirmButtonText: '好的'
            }
          )
          throw getErr(false)
        }
      })
    }
  }
  unlockInit (bc, err) {
    if (isIe()) {
      let Unlock
      try {
        let pluginEmbed = document.createElement('OBJECT')
        pluginEmbed.setAttribute('id', 'unlock')
        pluginEmbed.setAttribute('classid', 'clsid:B1E78CDA-D3BB-4DC6-9B5C-23FBE44159D8')
        pluginEmbed.setAttribute('width', '0')
        pluginEmbed.setAttribute('height', '0')
        document.body.appendChild(pluginEmbed)
        Unlock = document.getElementById('unlock')
        this.Unlock = Unlock
        this.Unlock.OpkiInit()
      } catch (error) {
        MessageBox.alert(
          '您还未安装豸信CA数字证书助手，请【下载安装】后进行解锁。',
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        if (err) { err() }
        return
      }
      let short = Unlock.GetPinStatus()
      if (short !== 0) {
        MessageBox.alert(
          '当前插入Ukey未被锁死，无法进行解锁操作。',
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        if (err) { err() }
      } else {
        bc(this.Unlock)
      }
    } else {
      return this.ukeyMiddlewareUnlock.handle('OpkiInit', {}).then(res => {
        if (res.code !== 0) { throw getErr('读取证书信息失败') }
        return this.ukeyMiddlewareUnlock.handle('GetPinStatus', {})
      }).then(res => {
        if (res.code !== 0) {
          MessageBox.alert(
            '当前插入Ukey未被锁死，无法进行解锁操作。',
            '提示',
            {
              type: 'warning',
              confirmButtonText: '好的'
            }
          )
          if (err) { err() }
        } else {
          bc(this.ukeyMiddlewareUnlock)
        }
      })
    }
  }


  disconnectUkey () {
    if (this.devHandle && this.devHandle !== '') {
      let devHandle = this.devHandle
      this.devHandle = ''
      return this.ukeyMiddleware.handle('DisconnectUkey', { devHandle })
    } else {
      return new Promise((resolve) => { resolve() })
    }
  }
}
